import { Link, useStaticQuery, graphql, withPrefix } from "gatsby";
import React, {
	useContext,
	useState,
	useEffect,
	useRef,
	createRef,
} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Button from "react-bootstrap/Button";
import { StaticImage, getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import { Helmet } from "react-helmet";
import gsap from "gsap";
import { motion } from "framer-motion";
import lottie from "lottie-web";

import animation from "../animations/testing.json";

import { MdEmail, MdOutlineDesktopMac, MdPhone } from "react-icons/md";
import { FaBuilding } from "react-icons/fa";
import { BsClockFill } from "react-icons/bs";
import ContactCard from "../components/contact-card";
import ContactForm from "../components/contactForm";

import Hero from "../components/hero";
import { StaticHero } from "../components/static-hero";
import XmasBanner from "../components/xmas-banner";

const IndexPage = () => {
	const tl = useRef();
	const data = useStaticQuery(graphql`
		query {
			# heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
			# 	altText
			# 	localFile {
			# 		publicURL
			# 		childImageSharp {
			# 			original {
			# 				height
			# 				width
			# 			}
			# 			gatsbyImageData(
			# 				formats: [AUTO, WEBP]
			# 				quality: 100
			# 				transformOptions: { cropFocus: CENTER, fit: CONTAIN }
			# 				layout: CONSTRAINED
			# 				placeholder: BLURRED
			# 			)
			# 		}
			# 	}
			# }

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);
	// let animDiv = createRef();
	// useEffect(() => {
	// 	lottie.loadAnimation({
	// 		container: animDiv.current, // the dom element that will contain the animation: ;
	// 		renderer: "svg",
	// 		loop: true,
	// 		autoplay: true,
	// 		animationData: animation,
	// 	});
	// }, []);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const propertyManagers = [
		{
			fullName: "Darren Faulkner",
			title: "Associate Director / Property Manager",
			email: "darrenf@priorspm.com",
			phone: "01273 980 234",
		},
		{
			fullName: "Paul Gibbs",
			title: "Senior Property Manager",
			email: "paulg@priorspm.com",
			phone: "01273 980 233",
		},
		{
			fullName: "Olivia Bennett",
			title: "Assistant Property Manager",
			email: "oliviab@priorspm.com",
			phone: "01273 568702",
		},
		{
			fullName: "Adam McChesney",
			title: "Senior Property Manager",
			email: "adammc@priorspm.com",
			phone: "01273 980 239",
		},
		{
			fullName: "Harriet Hunt",
			title: "Property Manager",
			email: "harrieth@priorspm.com",
			phone: "01273 980235",
		},
	];

	const otherContactsRow1 = [
		{
			fullName: "John Pakapoukas",
			title: "Accounts Manager",
			email: "johnp@priorspm.com",
			phone: "01273 980 240",
		},
		{
			fullName: "Rachel Phillips",
			title: "Accounts",
			email: "rachelp@priorspm.com",
			phone: "01273 737 586",
		},
		{
			fullName: "Yvette Neto",
			title: "Accounts",
			email: "yvetten@priorspm.com",
			phone: "01273 980 237",
		},
	];
	const otherContactsRow2 = [
		{
			fullName: "Paul Phillips",
			title: "Director / Chartered Building Surveyor",
			email: "paulp@priorspm.com",
			phone: "01273 980 242",
		},
		{
			fullName: "Jon Mahoney",
			title: "Building Surveyor",
			email: "jonm@priorspm.com",
			phone: "07555 743 730",
		},
		{
			fullName: "Mark Carter",
			title: "Chartered Surveyor",
			title2: "(Leasehold Valuation)",
			email: "markc@priorspm.com",
			phone: "01273 980 238",
		},
		{
			fullName: "Maxine Cameron",
			title: "Accounts Manager",
			title2: "(Hampshire)",
			email: "maxinec@priorspm.com",
			phone: "01273 980236",
		},
	];

	const outOfHoursContacts = [
		{
			fullName: "M8FM Jason Pumfrey",
			email: "jason.pumfrey@m8fm.uk",
			phone: "07598 137888",
		},
		// {
		//   fullName: "Excelsior Colin Peters",
		//   title: "(Worthing and Lancing Properties)",
		//   email: "colinpeters88@hotmail.co.uk",
		//   phone: "07852 874 911",
		// },
	];
	// const pluginImage = getImage(data.heroImg.childImageSharp.gatsbyImageData);

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<LocalBusinessSchema />
			<GatsbySeo
				title="Priors PM | Property Management Hove"
				description="Established residential and commercial block management company based in Hove"
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: "Priors PM | Property Management Hove",
					description:
						"Established residential and commercial block management company based in Hove",
					// images: [
					// 	{
					// 		url: `${data.heroImg?.localFile.publicURL}`,
					// 		width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
					// 		height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
					// 		alt: `${data.heroImg?.altText}`,
					// 	},
					// ],
				}}
			/>

			<div>
				<Layout>
					{/* <Hero title={} description={} type={} backgroundImage={}/> */}
					<StaticHero
						backgroundImage="../images/Adelaide-Crescent-Hove-3.jpg"
						text={
							<>
								{" "}
								<div className="text-center">
									<p className="fs-2 mb-0 d-lg-inline">Chartered Surveyors</p>{" "}
									<span className="poppins-light fs-2 mx-2 d-none d-lg-inline">
										|
									</span>{" "}
									<span className="poppins-light fs-2 mx-2 d-lg-none d-block">
										-
									</span>{" "}
									<p className="fs-2 mb-0 d-lg-inline">Property Managers</p>{" "}
									<span className="poppins-light fs-2 mx-2 d-none d-lg-inline">
										|
									</span>{" "}
									<span className="poppins-light fs-2 mx-2 d-lg-none d-block">
										-
									</span>{" "}
									<p className="fs-2 mb-0 d-lg-inline">Valuers </p>
								</div>
							</>
						}
					/>
					{/* <XmasBanner /> */}
					<Container className="text-white pt-3 pt-lg-4">
						<section className="mb-5">
							<p>
								Our new website is under construction and we will be back online
								soon with a new look.
							</p>
							<p className="mb-4">
								If you need to contact a Property Manager or another member of
								our team, please see the contact details below. If you can’t
								find who you’re looking for please leave your information in the
								contact form below and one of our team will be in touch shortly.
							</p>
							<p className="text-light-grey">
								Our opening hours are Monday to Friday from 9:00am – 5:30pm
							</p>
							{/* <p className="poppins-semibold">
								We close on 24th December 2024 at 13.00 and reopen at 09.00 on
								2nd January 2025.
							</p> */}
						</section>
						<section className="mb-5">
							<div className="d-flex align-items-center">
								<FaBuilding className="fs-4 me-2" />
								<h2 className="m-0 p-0">Property Managers</h2>
							</div>
							<hr className="py-2" />
							<Row className="g-5 mb-3">
								{propertyManagers.map((manager) => {
									return <ContactCard person={manager} />;
								})}
							</Row>
						</section>
						<section className="mb-5">
							<div className="d-flex align-items-center">
								<MdOutlineDesktopMac className="fs-4 me-2" />
								<h2 className="m-0 p-0">Other contacts</h2>
							</div>
							<hr className="py-2" />
							<Row className="g-5 mb-5">
								{otherContactsRow1.map((manager) => {
									return <ContactCard person={manager} />;
								})}
							</Row>
							<Row className="g-5 mb-3">
								{otherContactsRow2.map((manager) => {
									return <ContactCard person={manager} />;
								})}
							</Row>
						</section>
						<section className="mb-6">
							<div className="d-flex align-items-center">
								<BsClockFill className="fs-4 me-2" />
								<h2 className="m-0 p-0">Out of hours contacts</h2>
							</div>
							<hr className="py-2" />
							<Row className="g-5 mb-3">
								{outOfHoursContacts.map((manager) => {
									return <ContactCard person={manager} />;
								})}
							</Row>
						</section>
						<section className="mb-6">
							<Row>
								<Col>
									<div className="text-center">
										<StaticImage
											quality="100"
											src="../images/icons/Vector.svg"
											alt="warning"
											placeholder="blurred"
											width={40}
											formats={["auto", "webp"]}
											className="mb-2"
										/>
										<div>
											<p className="fs-3 d-block d-lg-inline">
												EMERGENCY GAS LEAK
											</p>
											<p className="fs-3 d-none d-lg-inline"> – </p>
											<p className="fs-3 d-block d-lg-inline">
												<a href="tel:0800111999" className="contact-link">
													0800 111 999
												</a>
											</p>
										</div>
									</div>
								</Col>
							</Row>
						</section>
						<section className="mb-7">
							<Row>
								<h2 className="text-center">Contact Us</h2>
								<p className="text-center">
									If you would prefer, please leave your details here and one of
									our excellent property managers will get in touch with you
									shortly.
								</p>
								<ContactForm />
							</Row>
						</section>
						<section className="mb-4">
							<Row>
								<div className="d-lg-flex justify-content-between bg-primary">
									<StaticImage
										quality="100"
										src="../images/Regulated_by_RICS_Logo-White.png"
										alt="RICS logo"
										placeholder="blurred"
										width={390}
										formats={["auto", "webp"]}
										// transformOptions={{ fit: "contain" }}
										objectFit="contain"
										className="mb-5 mb-lg-2"
									/>
									<StaticImage
										quality="100"
										src="../images/ombudsman.jpg"
										alt="Property Ombudsman Logo"
										placeholder="blurred"
										width={390}
										objectFit="contain"
										formats={["auto", "webp"]}
										className="mb-4 mb-lg-2"
									/>
								</div>
							</Row>
						</section>
					</Container>
					{/* <BgImage
						Tag="section"
						className="hero"
						id="hero-home"
						image={pluginImage}
					>
						<Container className="h-100">
							<Row className="align-items-center h-100">
								<Col xs={12}>
									<h1
										className="text-uppercase text-white"
										id="home-hero-headline"
									>
										Lorem Ipsum
									</h1>
									<p className="text-white" id="home-hero-subheading">
										Lorem ipsum dolor sit amet, consectetur adipiscing elit.
										Nunc mollis nulla justo, quis venenatis enim tempor vitae.
										In hac habitasse platea dictumst. Nam lectus risus,
										convallis vel dictum eu, interdum a mi.
									</p>
								</Col>
							</Row>
						</Container>
					</BgImage> */}
				</Layout>
			</div>
		</>
	);
};

export default IndexPage;
